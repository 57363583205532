import React, { useContext } from 'react'
import LibraryWrapper from './LibraryWrapper'
import { ImpactContext } from '../../components/Layout';
import { Entity } from '@parallelpublicworks/entitysync/';
import { 
    InputTime
} from '../../components/library/entitysync'; 


function inputSelect() {
    const {user} = useContext(ImpactContext)
    return (
        <LibraryWrapper>
            <Entity componentId="user-entity" type="user--user" source={user}>
                <InputTime
                    field="input_time" 
                    label="Select Time"
                />
                <hr />
                <InputTime
                    field="input_error"
                    label="With Error"
                    helperText="Something wrong"
                    error
                />
                <hr />
                <InputTime
                    field="input_small"
                    label="With Error"
                    small
                />
            </Entity>
        </LibraryWrapper>
    )
}
export default inputSelect
